import React from "react";
import web from '../images/services/WebDevelopment.png';
import { Link } from "react-router-dom";
import Planning_stage from '../images/services/PlanningStage.png';
import Gather_content from '../images/services/GatherContent.png';
import Plan_structure from '../images/services/PlanStructure.png';
import Design from '../images/services/Design.png';
import Testing from '../images/services/testing.png';
import Live from '../images/services/Live.png';
import Custom from '../images/services/CustomWebDevelopment.png';
import Front from '../images/services/FrontWebDevelopment.png';
import OpenSource from '../images/services/OpenSource.png';
import Ecommerce from '../images/services/Ecommerce.png';

const Web_Development = () => {
    return (
        <div>
            <div className="web_services">
                <div className="container-fluid">
                    <i className="bubble"></i>
                    <i className="bubble"></i>
                    <div className="row align-items-center">
                        <div className="col-md-6">
                            <h3 className="web_title">Get web ready. With our web development ideas and solutions.</h3>
                            {/* <div>
                                <Link className="btn btn_web">Get Started</Link>
                            </div> */}
                        </div>
                        <div className="col-md-6">
                            <img src={web} alt="webimg" className="web_image"></img>
                        </div>
                    </div>
                </div>
            </div>
            <div className="web_development">
                <div className="container">
                    <h2 className="web_development_title text-center">Web Development</h2>
                    <div className="row align-items-center mt-5">
                        <div className="col-md-6">
                            <div className="row">
                                <div className="col-md-6">
                                    <div className="d-flex align-items-center">
                                        <div>
                                            <img src={Planning_stage} alt="" className="web_image"></img>
                                        </div>
                                        <div>
                                            <p className="web_processname">Planning Stage</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-6 content_col">
                                    <div className="d-flex align-items-center">
                                        <div>
                                            <img src={Gather_content} alt="" className="web_image"></img>
                                        </div>
                                        <div>
                                            <p className="web_processname">Gather Content</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="row mt-5 planning_col">
                                <div className="col-md-6">
                                    <div className="d-flex align-items-center">
                                        <div>
                                            <img src={Plan_structure} alt="" className="web_image"></img>
                                        </div>
                                        <div>
                                            <p className="web_processname">Planning Structure</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-6 design_col">
                                    <div className="d-flex align-items-center">
                                        <div>
                                            <img src={Design} alt="" className="web_image"></img>
                                        </div>
                                        <div>
                                            <p className="web_processname">Design</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="row mt-5 testing_col">
                                <div className="col-md-6">
                                    <div className="d-flex align-items-center">
                                        <div>
                                            <img src={Testing} alt="" className="web_image"></img>
                                        </div>
                                        <div>
                                            <p className="web_processname">Testing</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-6 live_col">
                                    <div className="d-flex align-items-center">
                                        <div>
                                            <img src={Live} alt="" className="web_image"></img>
                                        </div>
                                        <div>
                                            <p className="web_processname">Live</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-6">
                            <h4 className="web_services_title">Web Development Services: A website is a window through which
                                your business says hello to the world.</h4>
                            <p className="web_services_para">Get ready for a new generation of online opportunities with advanced
                                architecture and the latest technology to build versatile and future ready web applications using
                                object-oriented best practices, user experience and a comprehensive code base web software,
                                web development services, application and web portal solutions that blend the best of performance,
                                security and scalability. Whether you are a start-up or a transglobal enterprise, web development
                                services.</p>
                        </div>
                    </div>
                </div>
            </div>
            <div className="web_projects">
                <div className="container">
                    <h2 className="web_project_heading">Top Class Web Development for your Next Project with multi-dimensional
                        growth</h2>
                    <div className="row mt-5">
                        <div className="col-md-6">
                            <div className="card web_card mb-4">
                                <div className="card-body">
                                    <img src={Custom} alt="" className="web_project_image"></img>
                                    <h5 className="project_title mt-4">Custom Web Development</h5>
                                    <div className="mt-4">
                                        <p className="web_development_points">
                                            <i className="fa fa-check-circle"></i>&nbsp;&nbsp;<span>Customize everything from
                                                front to back end.</span>
                                        </p>
                                        <p className="web_development_points">
                                            <i className="fa fa-check-circle"></i>&nbsp;&nbsp;<span>Give your business a big push
                                                with full functionality & dynamic interactivity.</span>
                                        </p>
                                        <p className="web_development_points">
                                            <i className="fa fa-check-circle"></i>&nbsp;&nbsp;<span>Get a custom solution no matter
                                                what your business complexity.</span>
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="card web_card mb-4">
                                <div className="card-body">
                                    <img src={Front} alt="" className="web_project_image"></img>
                                    <h5 className="project_title mt-4">Frontend & Backend Development</h5>
                                    <div className="mt-4">
                                        <p className="web_development_points">
                                            <i className="fa fa-check-circle"></i>&nbsp;&nbsp;<span>Building modern day responsive
                                                websites or web app for mobility segment.</span>
                                        </p>
                                        <p className="web_development_points">
                                            <i className="fa fa-check-circle"></i>&nbsp;&nbsp;<span>Drive memorable features,
                                                rich user experiences.</span>
                                        </p>
                                        <p className="web_development_points">
                                            <i className="fa fa-check-circle"></i>&nbsp;&nbsp;<span>Optimize for cross-platform
                                                and cross-browser versatility including mobile.</span>
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-6">
                            <div className="card web_card mb-4">
                                <div className="card-body">
                                    <img src={OpenSource} alt="" className="web_project_image"></img>
                                    <h5 className="project_title mt-4">Open Source</h5>
                                    <div className="mt-4">
                                        <p className="web_development_points">
                                            <i className="fa fa-check-circle"></i>&nbsp;&nbsp;<span>Open source software benefits
                                                from community-driven security.</span>
                                        </p>
                                        <p className="web_development_points">
                                            <i className="fa fa-check-circle"></i>&nbsp;&nbsp;<span> Adopting web development
                                                technologies frees businesses from vendor lock-in.</span>
                                        </p>
                                        <p className="web_development_points">
                                            <i className="fa fa-check-circle"></i>&nbsp;&nbsp;<span>Access to the Source Code.</span>
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="card web_card">
                                <div className="card-body">
                                    <img src={Ecommerce} alt="" className="web_project_image"></img>
                                    <h5 className="project_title mt-4">Ecommerce Development</h5>
                                    <div className="mt-4">
                                        <p className="web_development_points">
                                            <i className="fa fa-check-circle"></i>&nbsp;&nbsp;<span>A well-designed interface enhances
                                                the user experience and encourages conversions.</span>
                                        </p>
                                        <p className="web_development_points">
                                            <i className="fa fa-check-circle"></i>&nbsp;&nbsp;<span>Provides
                                                businesses with valuable customer insights and analytics.</span>
                                        </p>
                                        <p className="web_development_points">
                                            <i className="fa fa-check-circle"></i>&nbsp;&nbsp;<span>Security is paramount in ecommerce
                                                websites.</span>
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};
export default Web_Development;
