import React from "react";
import { Link } from 'react-router-dom';
import Iot_Banner from '../images/services/Iot.png';
import Iot_Consulting from '../images/services/IotConsulting.png';
import Integration from '../images/services/Integration&Implementation.png';
import Analytics from '../images/services/Analytics&Insight.png';
import Iot_Development from '../images/services/IotDevelopment.png';
import Platform_Icons from '../images/services/PlatformIcons.png';
import Project_Briefing from '../images/services/ProjectBriefing.png';
import Pcb_Designing from '../images/services/PCBDesigning.png';
import Pcb_Demo from '../images/services/PCBDemo.png';
import Pcb_Layout from '../images/services/PCBSchematic.png';
import Smart_Industry from '../images/services/SmartIndustry.png';
import Smart_Home from '../images/services/SmartHome.png';
import Weather_Monitor from '../images/services/WeatherMonitoring.png';
import Smart_City from '../images/services/SmartCity.png';

const Iot = () => {
    return (
        <div>
            <div className="iot">
                <div className="container">
                    <i className="bubble"></i>
                    <i className="bubble"></i>
                    <i className="bubble"></i>
                    <div className="row align-items-center">
                        <div className="col-md-6">
                            <h4 className="iot_title">Internet of Things is transforming the physical objects that surround us into an ecosystem
                                of information that will enrich our lives. It's about embedding intelligence so things become
                                smarter and do more than they were proposed to do.
                            </h4>
                            {/* <Link to="#" className="btn btn-iot">Get Started</Link> */}

                        </div>
                        <div className="col-md-6">
                            <img src={Iot_Banner} alt="" className="iot_banner_img"></img>

                        </div>
                    </div>
                </div>
            </div>
            <div className="iot_development">
                <div className="container-fluid">
                    <h2 className="text-center iot_development_title">IoT Development and Consulting</h2>
                    <div className="row mt-4">
                        <div className="col-md-10 offset-md-1">
                            <p className="text-center iot_para">IoT or Internet of Things is a vital concept nowadays in all works of life. It's way too easy and
                                convenient to connect everything with everyone in this digital world. IoT is a network of physical
                                devices that can be embedded with software and other electronics so that data and information can be
                                shared. We stand amongst the leading <b>IoT Services and Solution Providers</b> to take your business
                                to a different level with innovative IoT powered solutions. Find the right IoT platform to give a kick
                                start to your business. We follow a refined approach to understand your business needs and find best-fit
                                IoT solutions that accelerate the marketing of your products or services with no risk factor involved.
                                We are here to improvise your business value with cost-effective and efficient solutions. IoT can be
                                experienced across all industries Healthcare, Manufacturing, Automobile, Retail, Energy, Construction,
                                Smart Cities.
                            </p>
                        </div>
                    </div>
                </div>
            </div>
            <div className="iot_services">
                <div className="container">
                    <h2 className="iot_services_title text-center">Services Offerings</h2>
                    <div className="row pt-3">
                        <div className="col-md-3 col-sm-6">
                            <div className="card align-items-center mb-3">
                                <div className="card-body">
                                    <img src={Iot_Consulting} alt="" className="iot_consulting_img"></img>
                                    <p className="iot_services_name">IoT Consulting</p>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-3 col-sm-6">
                            <div className="card align-items-center text-center mb-3">
                                <div className="card-body">
                                    <img src={Integration} alt="" className="iot_integration_img"></img>
                                    <p className="iot_services_name">Integration & Implementation</p>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-3 col-sm-6">
                            <div className="card align-items-center mb-3">
                                <div className="card-body">
                                    <img src={Analytics} alt="" className="iot_analytics_img"></img>
                                    <p className="iot_services_name">Analytics & Insight</p>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-3 col-sm-6">
                            <div className="card align-items-center">
                                <div className="card-body">
                                    <img src={Iot_Development} alt="" className="iot_development_img"></img>
                                    <p className="iot_services_name">IoT Development</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="iot_services_detail iot_diagonal_box">
                <div className="container iot_content">
                    <div className="row align-items-center">
                        <div className="col-md-6 text-center">
                            <img src={Iot_Consulting} alt="" className="iot_services_img consulting_img"></img>
                        </div>
                        <div className="col-md-6">
                            <h4 className="services_detail_title">IoT Consulting</h4>
                            <p className="services_detail_para pt-3">Providing insights to cliens for enabling them to
                                embark on their IoT journey. Facilitating Roadmap Creation, Domain-based Value Stream Mapping,
                                Business Case Preparation, Use Case Definition, Solution Blueprinting, Business KPIs and ROI Mapping.
                            </p>
                        </div>
                    </div>
                    <div className="row align-items-center">
                        <div className="col-md-6 order2">
                            <h4 className="services_detail_title">Integration & Implementation</h4>
                            <p className="services_detail_para pt-3">Providing end-to-end solutions, from sensor / equipment
                                integration & M2M Communication, to Business Intelligence Integration, by leveraging partner products
                                / platforms and our domain expertise.
                            </p>
                        </div>
                        <div className="col-md-6 text-center order1">
                            <img src={Integration} alt="" className="iot_services_img"></img>
                        </div>
                    </div>
                    <div className="row align-items-center">
                        <div className="col-md-6 text-center">
                            <img src={Analytics} alt="" className="iot_services_img analytics_img"></img>
                        </div>
                        <div className="col-md-6">
                            <h4 className="services_detail_title">Analytics & Insight</h4>
                            <p className="services_detail_para pt-3">Develop & deploy analytics for our offered solutions,
                                and based on applied analytics, generate actionable insights available to business users for
                                process & productivity optimization.
                            </p>
                        </div>
                    </div>
                    <div className="row align-items-center">
                        <div className="col-md-6 order4">
                            <h4 className="services_detail_title">IoT Development</h4>
                            <p className="services_detail_para pt-3">Bitbyte aligns a team of IoT developers who specialize
                                in custom IoT development. The team has varied industry exposure and can develop solution to
                                address any business need leveraging the full potential of IoT technology. Bitbyte follows the
                                process and documentation guideline provided by the framework. Project Status Report on weekly
                                basis with the client help to monitor the development and avoid gaps, if any.
                            </p>
                        </div>
                        <div className="col-md-6 text-center order3">
                            <img src={Iot_Development} alt="" className="iot_services_img development_img"></img>
                        </div>
                    </div>
                </div>
            </div>
            <div className="iot_works">
                <div className="container">
                    <h2 className="iot_works_title text-center">How to works</h2>
                    <div className="row pt-3">
                        <div className="col-md-3 col-sm-6">
                            <div className="card iot_works_card">
                                <div className="card-body">
                                    <img src={Project_Briefing} alt="" className="iot_works_image"></img>
                                    <p className="iot_works_heading">Project Briefing</p>
                                    <p className="iot_works_para">Detailed understanding of the client's requirement and
                                        expectation from the solution. Clear definition of the goals, data points to be captured
                                        and/or controlled, metrics, reports, risks and success criteria.
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-3 col-sm-6">
                            <div className="card iot_works_card">
                                <div className="card-body">
                                    <img src={Pcb_Designing} alt="" className="iot_works_image"></img>
                                    <p className="iot_works_heading">PCB Designing</p>
                                    <p className="iot_works_para">Create execution plan, develop application flow and wireframes,
                                        design and develop hardware (PCB Design, Fabrication and Assembling) and implement concept.
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-3 col-sm-6">
                            <div className="card iot_works_card">
                                <div className="card-body">
                                    <img src={Pcb_Demo} alt="" className="iot_works_image"></img>
                                    <p className="iot_works_heading">PCB Demo</p>
                                    <p className="iot_works_para">Install hardware and configure PoC. Demonstrate functional model,
                                        captured data points and showcase analytics, reports and controls.
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-3 col-sm-6">
                            <div className="card iot_works_card">
                                <div className="card-body">
                                    <img src={Pcb_Layout} alt="" className="iot_works_image"></img>
                                    <p className="iot_works_heading">PCB Schematic & Layout</p>
                                    <p className="iot_works_para">Review results vis-a-vis requirement and develop pilot or
                                        production plan.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="iot_industry_solutions">
                <div className="container">
                    <h2 className="iot_industry_title text-center">Industry Solutions</h2>
                    <div className="row pt-3">
                        <div className="col-md-4">
                            <div className="card iot_industry_card mb-4">
                                <div className="card-body">
                                    <img src={Smart_Industry} alt="" className="iot_industry_img"></img>
                                    <p className="text-center iot_industry_name">Smart Industry</p>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-4">
                            <div className="card iot_industry_card mb-4">
                                <div className="card-body">
                                    <img src={Smart_Home} alt="" className="iot_industry_img"></img>
                                    <p className="text-center iot_industry_name">Smart Home</p>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-4">
                            <div className="card iot_industry_card mb-4">
                                <div className="card-body">
                                    <img src={Weather_Monitor} alt="" className="iot_industry_img"></img>
                                    <p className="text-center iot_industry_name">Weather Monitoring</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-6 offset-md-3">
                            <div className="card iot_industry_card">
                                <div className="card-body">
                                    <img src={Smart_City} alt="" className="iot_industry_img"></img>
                                    <p className="text-center iot_industry_name">Smart City & Building</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="iot_advantages">
                <div className="container">
                    <h2 className="iot_advantage_title text-center">Advantage of IoT Application</h2>
                    <p className="iot_advantage_para text-center pt-3">There are many advantages of incorporating IoT into our lives, which can help individuals, businessess, and
                        society on a daily basis. IoT application can control and collect enormous data points directly from the device
                        or platform where it is embedded without any human intervention. The application of IoT is possible across
                        all industries and business challenges. IoT has a huge potential to transform any business and take to the
                        next level.
                    </p>
                    <div className="row align-items-center">
                        <div className="col-md-6 text-center">
                            <img src={Platform_Icons} alt="" className="iot_icons"></img>
                        </div>
                        <div className="col-md-6">
                            <p className="iot_advantage_point">
                                <i className="fa fa-check-circle"></i>&nbsp;&nbsp;<span>Automated processes through sensor coding
                                    to increase business opportunities</span>
                            </p>
                            <p className="iot_advantage_point">
                                <i className="fa fa-check-circle"></i>&nbsp;&nbsp;<span>Enhanced Asset Utilization, Real-time data
                                    collection for advanced analytics</span>
                            </p>
                            <p className="iot_advantage_point">
                                <i className="fa fa-check-circle"></i>&nbsp;&nbsp;<span>Efficient Processes optimization, based on
                                    data insight</span>
                            </p>
                            <p className="iot_advantage_point">
                                <i className="fa fa-check-circle"></i>&nbsp;&nbsp;<span>Improved Safety and Security control</span>
                            </p>
                            <p className="iot_advantage_point">
                                <i className="fa fa-check-circle"></i>&nbsp;&nbsp;<span>Data-driven decision making</span>
                            </p>
                            <p className="iot_advantage_point">
                                <i className="fa fa-check-circle"></i>&nbsp;&nbsp;<span>Cost saving</span>
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};
export default Iot;