import Routing from "./Components/WebRoutes/Routing";

function App() {
  return (
    <>
      <Routing />
    </>
  );
}

export default App;
