import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import footer_logo from "../images/footer/bitbyte-300x60.png";
const Footer = () => {
  const [showButton, setShowButton] = useState(false);

  useEffect(() => {
    window.addEventListener("scroll", () => {
      if (window.pageYOffset > 300) {
        setShowButton(true);
      } else {
        setShowButton(false);
      }
    });
  }, []);

  // This function will scroll the window to the top
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth", // for smoothly scrolling
    });
  };
  return (
    <>
      {showButton && (
        <div onClick={scrollToTop} className="back-to-top" title="Back to Top">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="10"
            height="10"
            fill="currentColor"
            class="bi bi-chevron-up"
            viewBox="0 0 16 16"
          >
            <path
              fill-rule="evenodd"
              d="M7.646 4.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1-.708.708L8 5.707l-5.646 5.647a.5.5 0 0 1-.708-.708l6-6z"
            />
          </svg>
        </div>
      )}
      <div>
        <div className="footer">
          <div className="footerSec">
            <div className="container footerTop">
              <div className="row">
                <div className="col-md-4">
                  <div className="footerInfo">
                    {/* <h4>ABOUT US</h4> */}
                    <h3>
                      <img src={footer_logo} alt="" className="img-fluid"></img>
                    </h3>
                    <p>
                      BitByte Software Technologies located in Indore, with
                      capabilities to service clients across M.P. is a rapidly
                      growing Website Design, Development and Custom Software
                      Development company.
                    </p>
                  </div>
                </div>
                {/* <div className="col-md-3">
                  <h4>FOLLOWS US ON FACEBOOK</h4>
                </div> */}
                <div className="col-md-4 footerLinks">
                  <h4>QUICK LINKS</h4>
                  <ul>
                    <li>
                      <Link to="/company">Company</Link>
                    </li>
                    <li>
                      <Link to="/careers">Careers</Link>
                    </li>
                    <li>
                      <Link to="#">Privacy Policy</Link>
                    </li>
                    <li>
                      <Link to="/portfolio">Portfolio</Link>
                    </li>
                    <li>
                      <Link to="/contact">Contact</Link>
                    </li>
                  </ul>
                </div>
                <div className="col-md-4">
                  <div className="footerLinks">
                    <h4>CONTACT US</h4>

                    <p>
                      <strong>Call us :</strong> 0731-2970998, +91-9993150998
                    </p>
                    <p>
                      <strong>Email us :</strong> info@bitbyteindia.com,
                      indiabitbyte@gmail.com
                    </p>
                    <p>
                      <strong>Address :</strong> 402, 4th floor, Atulya IT Park,
                      MPIDC, Khandwa Road Indore (M.P.), 452001
                    </p>
                    <div className="socialLinks">
                      <p className="follow">Follow Us On :</p>

                      <Link to="https://in.linkedin.com/company/bitbyte-software-technology">
                        <i
                          className="fab fa-linkedin-in"
                          aria-hidden="true"
                        ></i>
                      </Link>
                      {/* <Link to="https://twitter.com/ROBOTRONiX2010?s=08">
                        <i className="fab fa-twitter" aria-hidden="true"></i>
                      </Link> */}
                      <Link to="https://www.instagram.com/bitbyteindia/?hl=en">
                        <i className="fab fa-instagram" aria-hidden="true"></i>
                      </Link>
                      <Link to="https://www.facebook.com/itparkindore/">
                        <i className="fab fa-facebook-f" aria-hidden="true"></i>
                      </Link>
                      {/* <Link to="https://www.youtube.com/channel/UCrsYGH36ulMTDbzRhMvLtJg">
                        <i className="fab fa-youtube" aria-hidden="true"></i>
                      </Link> */}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="footerBottom">
              <div className="container">
                <div className="row">
                  <div className="col-md-12 text-center">
                    <p>
                      Copyright &copy;2023, All Right Reserved &nbsp;
                      {/* <a href="https://bitbyteindia.com/" target="_blank">
                        Bitbyteindia.com
                      </a> */}
                       <a href="#">
                        Bitbyteindia.com
                      </a>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Footer;
