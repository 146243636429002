import React, { useState, useEffect } from "react";

const Contact = () => {
    const server_url = process.env.REACT_APP_SERVER_URL;
    const [GenCaptcha, setGenCaptcha] = useState("");
    const [msg, setMsg] = useState("");
    const [userData, setUserData] = useState({
        fullName: "",
        email: "",
        companyName: "",
        companyLocation: "",
        phone: "",
        budget: "",
        aboutProject: "",
        captchaText: ""
    })

    let name, value;
    const handleFormData = (e) => {
        name = e.target.name;
        value = e.target.value;

        setUserData({ ...userData, [name]: value })
        console.log(userData)
    }

    const sendContact = async (e) => {
        e.preventDefault();

        const formData = new FormData();
        formData.set('fullName', userData.fullName);
        formData.set('email', userData.email);
        formData.set('companyName', userData.companyName);
        formData.set('companyLocation', userData.companyLocation);
        formData.set('phone', userData.phone);
        formData.set('budget', userData.budget);
        formData.set('aboutProject', userData.aboutProject);
        formData.set('captcha', GenCaptcha);
        formData.set('captchaText', userData.captchaText);

        const res = await fetch(`${server_url}/contact`, {
            method: "post",
            body: formData
        })
        const data = await res.json();
        if (data.status === 422 || data.error) {
            setMsg(data);
            setTimeout(() => {
                setMsg("");
            }, 5000);
            generate();
        } else {
            console.log(data)
            setMsg(data);
            setTimeout(() => {
                setMsg("");
            }, 5000);
            setUserData({
                fullName: "",
                email: "",
                companyName: "",
                companyLocation: "",
                phone: "",
                budget: "",
                aboutProject: "",
                captchaText: ""
            });
            generate();
        }
    }
    function generate() {
        let uniquechar = "";
        const randomchar = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
        for (let i = 1; i <= 6; i++) {
            uniquechar += randomchar.charAt(Math.random() * randomchar.length);
        }
        // Store generated input
        setGenCaptcha(uniquechar);
    }
    useEffect(() => {
        generate();
    }, [])

    return (
        <>
            <div className="contactForm">
                <div className="container">
                    <h2 className="contact_title">Get Started Now</h2>
                    <p className="contact_subtitle">We are here to grow your business through technology services. Let's Connect!</p>
                    <div className="row">
                        <div className="col-md-12">
                            <div className="card">
                                <div className="card-body">
                                    <form method="POST" id="reset" encType="multipat/form-data" onSubmit={sendContact}>
                                        <div className="row pt-3">
                                            <div className="col-md-6">
                                                <label>Full Name*</label>
                                                <input type="text"
                                                    name="fullName"
                                                    value={userData.fullName}
                                                    className="form-control contact_field"
                                                    autoComplete="new-password"
                                                    onChange={handleFormData}
                                                />

                                            </div>
                                            <div className="col-md-6 common_field">
                                                <label>Company Name*</label>
                                                <input type="text"
                                                    name="companyName"
                                                    value={userData.companyName}
                                                    className="form-control contact_field"
                                                    autoComplete="new-password"
                                                    onChange={handleFormData}
                                                />
                                            </div>
                                        </div>
                                        <div className="row pt-3">
                                            <div className="col-md-6">
                                                <label>Company Location*</label>
                                                <input type="text"
                                                    name="companyLocation"
                                                    value={userData.companyLocation}
                                                    className="form-control contact_field"
                                                    autoComplete="new-password"
                                                    onChange={handleFormData}
                                                />

                                            </div>
                                            <div className="col-md-6 common_field">
                                                <label>Work Email Address*</label>
                                                <input type="email"
                                                    name="email"
                                                    value={userData.email}
                                                    className="form-control contact_field"
                                                    autoComplete="new-password"
                                                    onChange={handleFormData}
                                                />
                                            </div>
                                        </div>
                                        <div className="row pt-3">
                                            <div className="col-md-6">
                                                <label>Phone Number*</label>
                                                <input type="text"
                                                    name="phone"
                                                    value={userData.phone}
                                                    className="form-control contact_field"
                                                    autoComplete="new-password"
                                                    onChange={handleFormData}

                                                />

                                            </div>
                                            <div className="col-md-6 common_field">
                                                <label>Approx Budget*</label>
                                                <input type="text"
                                                    name="budget"
                                                    value={userData.budget}
                                                    className="form-control contact_field"
                                                    autoComplete="new-password"
                                                    onChange={handleFormData}
                                                />
                                            </div>
                                        </div>
                                        <div className="row pt-3">
                                            <div className="col-md-12">
                                                <label>Tell us about your project*</label>
                                                <textarea
                                                    value={userData.aboutProject}
                                                    name="aboutProject"
                                                    rows="6"
                                                    className="form-control contact_field"
                                                    autoComplete="new-password"
                                                    onChange={handleFormData}
                                                />
                                            </div>
                                        </div>
                                        <div className="row pt-3">
                                            <div className="col-md-12">
                                                <label>Captcha*</label>
                                                <input type="text" className="form-control contact_field"
                                                    value={userData.captchaText}
                                                    name="captchaText"
                                                    onChange={handleFormData}
                                                />
                                            </div>
                                        </div>
                                        <div className="form-group pt-3">
                                            <input type="text" className="captcha_code contact_field" placeholder={GenCaptcha} disabled></input>
                                            <i className="fa fa-refresh" onClick={generate}></i>
                                        </div>
                                        <div className="form-group pt-3">
                                            <input type="submit" value="Submit" className="contact-btn" />
                                        </div>

                                        {msg.message ? <div className="text-success text-center mb-3">
                                            {msg.message}
                                        </div> : ""}
                                        {msg.error ?
                                            <div className="text-danger text-center mb-3">
                                                {msg.error}
                                            </div> : ""}
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="map">
                    <div style={{marginLeft:"10px", marginRight:"10px"}}>
                            <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3681.108797985202!2d75.86884417594271!3d22
                            .686993428766726!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3962fce4ee30b14f%3A0xcfc953cb64216669!2sBitByte%20Software%20Technology!5e0!3m2!1sen!2sin!4v1688196292042!5m2!1sen!2sin" width="100%" height="450" 
                            referrerpolicy="no-referrer-when-downgrade"></iframe>
                    </div>
                </div>
            </div>
        </>
    )
}
export default Contact;