import React from "react";
import {Link} from 'react-router-dom';
import cms from "../images/services/CMS.png";
import plStage from "../images/services/PlanningStage.png";
import plStruc from "../images/services/PlanStructure.png";
import testing from "../images/services/testing.png";
import gatherContent from "../images/services/GatherContent.png";
import design from "../images/services/Design.png";
import live from "../images/services/Live.png";
import wordPress from "../images/services/WordPress_blue_logo.png";
const CMS_Development = () => {
  return (
    <>
      <div className="cms_services cms_diagonal_box">
        <div className="container cms_content">
          <i className="bubble"></i>
          <i className="bubble"></i>
          <i className="bubble"></i>
          <div className="row align-items-center">
            <div className="col-md-6">
              <h3 className="cms_title">According to different surveys, there are approximately 2 billion websites online today, and most of them are CMS
                based solutions.</h3>
              <p className="cms_para">We provide custom wordpress development services for businesses spanning every sector and market. We leverage the
                power of WordPress to build exceptional websites that meets your business goals.</p>
              {/* <Link to="#" className="btn btn-cms">Get Started</Link> */}
            </div>
            <div className="col-md-6">
              <img src={cms} alt="" className="cms_img"></img>
            </div>
          </div>
        </div>
      </div>
      <div className="cms_tools">
        <div className="container">
          <h2 className="cms_tools_title text-center"> Web Based CMS tools for simple to complex websites
            <br />
            Content Management System</h2>
          <div className="row align-items-center mt-5">
            <div className="col-md-6">
              <h4 className="cms_tools_subtitle">
                Manage your content. According to your business needs
              </h4>
              <p className="cms_tools_para">
                We make content the creation process easy, quick, and
                highly productive to keep your site fresh, updated, and
                relevant to our WordPress CMS Development abilities. We
                can enhance the performance and capabilities of your
                WordPress site with various optimizations including
                performance, conversion, and search engine optimization.
                For responsive web design, we optimize the site for
                mobiles, tablets, etc. For future integrations, we
                optimize the website for upcoming technologies and trends.
              </p>
            </div>
            <div className="col-md-6">
              <div className="row">
                <div className="col-md-6 stage_col">
                  <div className="d-flex align-items-center">
                    <div>
                      <img src={plStage} alt="" className="cms_image"></img>
                    </div>
                    <div>
                      <p className="cms_processname">Planning Stage</p>
                    </div>
                  </div>
                </div>
                <div className="col-md-6 content_col">
                  <div className="d-flex align-items-center">
                    <div>
                      <img src={gatherContent} alt="" className="cms_image"></img>
                    </div>
                    <div>
                      <p className="cms_processname">Gather Content</p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="row mt-5 planning_col">
                <div className="col-md-6">
                  <div className="d-flex align-items-center">
                    <div>
                      <img src={plStruc} alt="" className="cms_image"></img>
                    </div>
                    <div>
                      <p className="cms_processname">Planning Structure</p>
                    </div>
                  </div>
                </div>
                <div className="col-md-6 design_col">
                  <div className="d-flex align-items-center">
                    <div>
                      <img src={design} alt="" className="cms_image"></img>
                    </div>
                    <div>
                      <p className="cms_processname">Design</p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="row mt-5 testing_col">
                <div className="col-md-6">
                  <div className="d-flex align-items-center">
                    <div>
                      <img src={testing} alt="" className="cms_image"></img>
                    </div>
                    <div>
                      <p className="cms_processname">Testing</p>
                    </div>
                  </div>
                </div>
                <div className="col-md-6 live_col">
                  <div className="d-flex align-items-center">
                    <div>
                      <img src={live} alt="" className="cms_image"></img>
                    </div>
                    <div>
                      <p className="cms_processname">Live</p></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="cms_expertise">
        <div className="container cms_expertise_box">
          <div className="cms_expertise_content">
            <h2 className="cms_expertise_title text-center">We Expertise in CMS Development using WordPress</h2>
            <div className="row pt-5">
              <div className="col-md-6 text-center">
                <img src={wordPress} alt="" className="wordpress_logo"></img>
                <p className="wordpress_title">WORDPRESS</p>
              </div>
              <div className="col-md-6">
                <p className="wordpress_para">Grow your online presence flexibly with the free and easy to use WordPress,
                  the most popular CMS in the world today, that comes with powerful design, community support and search features.
                </p>
                <p className="wordpress_para">Bitbyte's CMS website development team focuses on innovation, more than
                  anything else. We offer the best set of WordPress plugins, advanced templates, and exceptional menu management
                  options to our clients.
                </p>
              </div>
            </div>
            <div className="row pt-3">
              <div className="col-md-6">
                <p className="wordpress_features">Major features of the wordpress are - </p>
                <p className="wordpress_point">
                  <i className="fa fa-check-circle"></i>&nbsp;&nbsp;<span>Core CMS Features</span>
                </p>
                <p className="wordpress_point">
                  <i className="fa fa-check-circle"></i>&nbsp;&nbsp;<span>Responsive Design</span>
                </p>
                <p className="wordpress_point">
                  <i className="fa fa-check-circle"></i>&nbsp;&nbsp;<span>Highly Flexible E-Commerce Platform</span>
                </p>
                <p className="wordpress_point">
                  <i className="fa fa-check-circle"></i>&nbsp;&nbsp;<span>SEO-Friendly</span>
                </p>
                <p className="wordpress_point">
                  <i className="fa fa-check-circle"></i>&nbsp;&nbsp;<span>Customizable Website Development Platform</span>
                </p>
                <p className="wordpress_point">
                  <i className="fa fa-check-circle"></i>&nbsp;&nbsp;<span>Blog Management</span>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default CMS_Development;

