import React from "react";
import DigitalMarketing from '../images/services/DigitalMarketing.png';
import { Link } from 'react-router-dom';
import DriveTraffic from '../images/services/DriveTraffic.png';
import Focused from '../images/services/FocusedOnDelivering.png';
import FullyIntegrated from '../images/services/FullyIntegrated.png';
import OnlineBranding from '../images/services/OnlineBranding.png';
import ContentMarketing from '../images/services/ContentMarketing.png';
import SocialMedia from '../images/services/SocialMedia.png';
import EmailMarketing from '../images/services/E-MailMarketing.png';
import MobileAds from '../images/services/MobileAds.png';
import Conference from '../images/services/ConferenceClipart.png';

const Digital_Marketing = () => {
    return (
        <div>
            <div className="digital_marketing_banner">
                <div className="container">
                    <i className="bubble"></i>
                    <i className="bubble"></i>
                    <i className="bubble"></i>
                    <div className="row align-items-center">
                        <div className="col-md-6">
                            <h3 className="digital_marketing_title">Digital marketing is data-driven and targeted brand promotion through technology.</h3>
                            <p className="digital_marketing_para">Bitbyte evaluate your website traffic, determine the best online platforms to invest in,
                                and continually maintain the balance between your marketing activities and the result
                                we provide.
                            </p>
                            {/* <Link to="#" className="btn btn-digital">Get Started</Link> */}


                        </div>
                        <div className="col-md-6">
                            <img src={DigitalMarketing} alt="" className="digital_marketing_img"></img>
                        </div>
                    </div>
                </div>
            </div>
            <div className="digital_marketing">
                <div className="container">
                    <h2 className="digital_marketing_heading text-center">Digital Marketing</h2>
                    <p className="digital_universe_para text-center pt-3">Digital universe is the most thriving market in today's world. Digital Marketing has become absolutely
                        essential not only from the vendor point of promotion, but also for its unparalleled convenience and
                        liberating experience in the consumer point of consumption. It allows for a direct connection with the
                        consumers that makes your business more engaging and responsive, which directly contributes to the
                        continuous fefinement of the strategies involved, for a more successful business.
                    </p>
                    <div className="row">
                        <div className="col-md-10 offset-md-1">
                            <div className="row">
                                <div className="col-md-4">
                                    <div className="card marketing_card">
                                        <div className="card-body">
                                            <img src={DriveTraffic} alt="" className="digital_marketing_icon"></img>
                                            <p className="marketing_topic pt-3">DRIVE TRAFFIC AND INCREASE SALES</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-4">
                                    <div className="card marketing_card">
                                        <div className="card-body">
                                            <img src={Focused} alt="" className="digital_marketing_icon"></img>
                                            <p className="marketing_topic pt-3">FOCUSED ON DELIVERING MEASURABLE RESULTS</p>

                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-4">
                                    <div className="card marketing_card">
                                        <div className="card-body">
                                            <img src={FullyIntegrated} alt="" className="digital_marketing_icon"></img>
                                            <p className="marketing_topic pt-3">FULLY INTEGRATED DIGITAL MARKETING</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="digital_marketing_types">
                <div className="container digital_marketing_container">
                    {/* <div className="row">
                        <div className="col-md-10 offset-md-1"> */}
                    <div className="row">
                        <div className="col-md-4">
                            <div className="card marketing_types mb-3">
                                <div className="card-body">
                                    <img src={OnlineBranding} alt="" className="marketing_image"></img>
                                    <h6 className="marketing_types_title pt-3">Online Branding</h6>
                                    <p className="marketing_types_para">Online branding should deliver the same principles of experience that you wish to
                                        deliver for your customers online; including accessibility, whether digital is your
                                        main sales channel or not. There is through a long way to go for this to be taken as
                                        seriously as it should.
                                    </p>
                                    <p className="marketing_types_subtitle">Brand Building - Re-branding - Brand Protection</p>
                                </div>
                                <span class="top"></span>
                                <span class="right"></span>
                                <span class="bottom"></span>
                                <span class="left"></span>
                            </div>
                        </div>
                        <div className="col-md-4">
                            <div className="card marketing_types mb-3">
                                <div className="card-body">
                                    <img src={OnlineBranding} alt="" className="marketing_image"></img>
                                    <h6 className="marketing_types_title pt-3">SEO Services</h6>
                                    <p className="marketing_types_para">There are a variety of SEO services which offer solutions for ranking issues, and deficiencies.
                                        Depending on your goals, and needs one, or a combination, of the below services may be right
                                        for your website.
                                    </p>
                                    <p className="marketing_types_subtitle">Website SEO Audit - On-Page SEO - Off-Page SEO - SEO Content Development - Code
                                        Optimization - Local Business SEO
                                    </p>
                                </div>
                                <span class="top"></span>
                                <span class="right"></span>
                                <span class="bottom"></span>
                                <span class="left"></span>
                            </div>
                        </div>
                        <div className="col-md-4">
                            <div className="card marketing_types">
                                <div className="card-body">
                                    <img src={ContentMarketing} alt="" className="marketing_image"></img>
                                    <h6 className="marketing_types_title pt-3">Content Marketing</h6>
                                    <p className="marketing_types_para">Content Marketing is a strategic marketing approach focused on creating and distributing
                                        valuable, relevant, and consistent content to attract and retain a clearly defined
                                        audience - and, ultimately, to drive profitable customer action.
                                    </p>
                                    <p className="marketing_types_subtitle">Blog Posts - Ebooks - Workbooks and Templates</p>
                                </div>
                                <span class="top"></span>
                                <span class="right"></span>
                                <span class="bottom"></span>
                                <span class="left"></span>
                            </div>
                        </div>
                    </div>
                    <div className="row pt-3">
                        <div className="col-md-4">
                            <div className="card marketing_types mb-3">
                                <div className="card-body">
                                    <img src={SocialMedia} alt="" className="marketing_image"></img>
                                    <h6 className="marketing_types_title pt-3">Social Media</h6>
                                    <p className="marketing_types_para">Social media marketing refers to the process of gaining traffic or attention
                                        through social media sites. Social media itself is a catch-all term for sites
                                        that may provide radically different social actions.
                                    </p>
                                    <p className="marketing_types_subtitle">Social Media Optimization (SMO) - Social Media Marketing (SMM) </p>
                                </div>
                                <span class="top"></span>
                                <span class="right"></span>
                                <span class="bottom"></span>
                                <span class="left"></span>
                            </div>
                        </div>
                        <div className="col-md-4">
                            <div className="card marketing_types mb-3">
                                <div className="card-body">
                                    <img src={EmailMarketing} alt="" className="marketing_image"></img>
                                    <h6 className="marketing_types_title pt-3">E-Mail Marketing</h6>
                                    <p className="marketing_types_para">Email marketing is a way of promoting products or services through email.
                                        Email marketing is a top digital media channel and is important for customer's
                                        acquisition and retention.
                                    </p>
                                    <p className="marketing_types_subtitle">Individuals emails - Newsletters - Responsive templates - Campaign follow-ups</p>
                                </div>
                                <span class="top"></span>
                                <span class="right"></span>
                                <span class="bottom"></span>
                                <span class="left"></span>
                            </div>
                        </div>
                        <div className="col-md-4">
                            <div className="card marketing_types mb-3">
                                <div className="card-body">
                                    <img src={MobileAds} alt="" className="marketing_image"></img>
                                    <h6 className="marketing_types_title pt-3">Digital Advertising</h6>
                                    <p className="marketing_types_para">Digital advertising allows marketers to target potential customers with pinpoint
                                        accuracy to get market. Audiences can be targeted by demographics, interests, recent
                                        online behavior and activity.
                                    </p>
                                    <p className="marketing_types_subtitle">Pay Per Click (PPC) - Banner Ads - Display Ads - Social Media Ads</p>
                                </div>
                                <span class="top"></span>
                                <span class="right"></span>
                                <span class="bottom"></span>
                                <span class="left"></span>
                            </div>
                        </div>
                    </div>
                    {/* </div>
                    </div> */}
                </div>
            </div>
            <div className="digital_marketing_practices">
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-md-6">
                            <img src={Conference} alt="" className="conference_image"></img>
                        </div>
                        <div className="col-md-6">
                            <h3 className="marketing_practices_title">Power Practices that set us powerfully apart</h3>
                            <p className="marketing_practices_point">
                                <i className="fa fa-check-circle"></i>&nbsp;&nbsp;<span>Always respond to customer comments and inquiries.</span>
                            </p>
                            <p className="marketing_practices_point">
                                <i className="fa fa-check-circle"></i>&nbsp;&nbsp;<span>Digital Marketing Campaign Strategy.</span>
                            </p>
                            <p className="marketing_practices_point">
                                <i className="fa fa-check-circle"></i>&nbsp;&nbsp;<span>Make content more useful with outbound links.</span>
                            </p>
                            <p className="marketing_practices_point">
                                <i className="fa fa-check-circle"></i>&nbsp;&nbsp;<span>Data-Driven Approach.</span>
                            </p>
                            <p className="marketing_practices_point">
                                <i className="fa fa-check-circle"></i>&nbsp;&nbsp;<span>Weekly Strategy Reviews, Live Reporting.</span>
                            </p>
                            <p className="marketing_practices_point">
                                <i className="fa fa-check-circle"></i>&nbsp;&nbsp;<span>Determine relevant keywords.</span>
                            </p>
                            <p className="marketing_practices_point">
                                <i className="fa fa-check-circle"></i>&nbsp;&nbsp;<span>Routine and Periodic Communication.</span>
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    );
};
export default Digital_Marketing;
