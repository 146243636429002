import React, { useState, useEffect } from 'react';
import company_logo from "../images/header/company-logo.png";
import { NavLink, Link } from 'react-router-dom';
import { FiAlignRight, FiChevronDown, FiX } from "react-icons/fi";
const Navbarmenu = () => {
  const [isMenu, setisMenu] = useState(false);
  const [isResponsiveclose, setResponsiveclose] = useState(false);
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => {
      setScreenWidth(window.innerWidth);
    };
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const toggleClass = () => {
    setisMenu(prevState => !prevState);
    setResponsiveclose(prevState => !prevState);
  };
  let boxClass = ["main-menu menu-right menuq1"];
  if (isMenu) {
    boxClass.push('menuq2');
  } else {
    boxClass.push('');
  }
  const [isMenuSubMenu, setMenuSubMenu] = useState(false);
  const toggleSubmenu = () => {
    setMenuSubMenu(isMenuSubMenu === false ? true : false);
  };
  let boxClassSubMenu = ["sub__menus"];
  if (isMenuSubMenu) {
    boxClassSubMenu.push('sub__menus__Active');
  } else {
    boxClassSubMenu.push('');
  }

  const [subMenu, setSubMenu] = useState(false);
  const dropdown = () => {
    setSubMenu(subMenu === false ? true : false);
  };
  let boxDropdown = ["sub__menus"];
  if (subMenu) {
    boxDropdown.push('sub__menus__Active');
  }
  else {
    boxDropdown.push('');
  }
  return (
    <>
      <header className="header__middle container__navbar">
        <div className="row">
          <div className="header__middle__logo">
            <Link to="/"><img src={company_logo} alt="" className='logo'></img></Link>
          </div>
          <div className="header__middle__menus">
            <div className="main-nav ">
              {/* Responsive Menu Button */}
              {isResponsiveclose ? (
                <>
                  <span
                    className="menubar__button"
                    style={{ display: "none" }}
                    onClick={toggleClass}
                  >
                    {" "}
                    <FiX />{" "}
                  </span>
                </>
              ) : (
                <>
                  <span
                    className="menubar__button"
                    style={{ display: "none" }}
                    onClick={toggleClass}
                  >
                    {" "}
                    <FiAlignRight />{" "}
                  </span>
                </>
              )}
              <ul className={boxClass.join(" ")}>
                {screenWidth > 1021 ? (
                  <li
                    onClick={dropdown}
                    className="menu-item sub__menus__arrows"
                  >
                    {" "}
                    <Link to="#">
                      {" "}
                      ABOUT US <FiChevronDown />{" "}
                    </Link>
                    <ul className={boxDropdown.join(" ")}>
                      <li>
                        {" "}
                        <NavLink
                          onClick={toggleClass}
                          activeClassName="is-active"
                          to="/company"
                        >
                          {" "}
                          COMPANY {" "}
                        </NavLink>{" "}
                      </li>
                      <li>
                        <NavLink
                          onClick={toggleClass}
                          activeClassName="is-active"
                          to="/careers"
                        >
                          {" "}
                          CAREERS {" "}
                        </NavLink>{" "}
                      </li>
                    </ul>
                  </li>
                ) : (
                  <>
                    <li
                      onClick={dropdown}
                      className="menu-item sub__menus__arrows"
                    >
                      {" "}
                      <Link to="#">
                        {" "}
                        ABOUT US <FiChevronDown />{" "}
                      </Link>
                    </li>
                    {subMenu && (<><li className="menu-item">
                      {" "}
                      <NavLink
                        onClick={toggleClass}
                        activeClassName="is-active sakshi"
                        to="/company"
                      >
                        {" "}
                        COMPANY {" "}
                      </NavLink>{" "}
                    </li>
                      <li className="menu-item">
                        <NavLink
                          onClick={toggleClass}
                          activeClassName="is-active"
                          to="/careers"
                        >
                          {" "}
                          CAREERS{" "}
                        </NavLink>{" "}
                      </li></>)}
                  </>
                )}
                {screenWidth > 1021 ? (
                  <li
                    onClick={toggleSubmenu}
                    className="menu-item sub__menus__arrows"
                  >
                    {" "}
                    <Link to="#">
                      {" "}
                      SERVICES <FiChevronDown />{" "}
                    </Link>
                    <ul className={boxClassSubMenu.join(" ")}>
                      <li>
                        {" "}
                        <NavLink
                          onClick={toggleClass}
                          activeClassName="is-active"
                          to="/web-development"
                        >
                          {" "}
                          WEB DEVELOPMENT{" "}
                        </NavLink>{" "}
                      </li>
                      <li>
                        <NavLink
                          onClick={toggleClass}
                          activeClassName="is-active"
                          to="/internet-of-things"
                        >
                          {" "}
                          INTERNET OF THINGS{" "}
                        </NavLink>{" "}
                      </li>
                      <li>
                        <NavLink
                          onClick={toggleClass}
                          activeClassName="is-active"
                          to="/mobile-app-development"
                        >
                          {" "}
                          MOBILE APP DEVELOPMENT{" "}
                        </NavLink>{" "}
                      </li>
                      <li>
                        <NavLink
                          onClick={toggleClass}
                          activeClassName="is-active"
                          to="/cms-development"
                        >
                          {" "}
                          CMS SOLUTIONS{" "}
                        </NavLink>{" "}
                      </li>
                      <li>
                        <NavLink
                          onClick={toggleClass}
                          activeClassName="is-active"
                          to="/digital-marketing"
                        >
                          {" "}
                          DIGITAL MARKETING{" "}
                        </NavLink>{" "}
                      </li>
                    </ul>
                  </li>
                ) : (
                  <>
                    <li
                      onClick={toggleSubmenu}
                      className="menu-item sub__menus__arrows"
                    >
                      {" "}
                      <Link to="#">
                        {" "}
                        SERVICES <FiChevronDown />{" "}
                      </Link>
                    </li>
                    {isMenuSubMenu && (<><li className="menu-item">
                      {" "}
                      <NavLink
                        onClick={toggleClass}
                        activeClassName="is-active"
                        to="/web-development"
                      >
                        {" "}
                        WEB DEVELOPMENT {" "}
                      </NavLink>{" "}
                    </li>
                      <li className="menu-item">
                        <NavLink
                          onClick={toggleClass}
                          activeClassName="is-active"
                          to="/internet-of-things"
                        >
                          {" "}
                          INTERNET OF THINGS{" "}
                        </NavLink>{" "}
                      </li>
                      <li className="menu-item">
                        <NavLink
                          onClick={toggleClass}
                          activeClassName="is-active"
                          to="/mobile-app-development"
                        >
                          {" "}
                          MOBILE APP DEVELOPMENT{" "}
                        </NavLink>{" "}
                      </li>
                      <li className="menu-item">
                        <NavLink
                          onClick={toggleClass}
                          activeClassName="is-active"
                          to="/cms-development"
                        >
                          {" "}
                          CMS SOLUTIONS{" "}
                        </NavLink>{" "}
                      </li>
                      <li className="menu-item">
                        <NavLink
                          onClick={toggleClass}
                          activeClassName="is-active"
                          to="/digital-marketing"
                        >
                          {" "}
                          DIGITAL MARKETING{" "}
                        </NavLink>{" "}
                      </li></>)}
                  </>
                )}
                <li className="menu-item ">
                  <NavLink
                    onClick={toggleClass}
                    activeClassName="is-active"
                    to="/portfolio"
                  >
                    {" "}
                    PORTFOLIO{" "}
                  </NavLink>{" "}
                </li>
                <li className="menu-item ">
                  <NavLink
                    onClick={toggleClass}
                    activeClassName="is-active"
                    to="/contact"
                  >
                    {" "}
                    CONTACT{" "}
                  </NavLink>{" "}
                </li>
                <li className="menu-btn">
                  <NavLink
                    onClick={toggleClass}
                    activeClassName="is-active" className="btn btn_started"
                    to="#"
                  >
                    {" "}
                    GET STARTED WITH US{" "}
                  </NavLink>{" "}
                </li>
              </ul>
            </div>
          </div>
        </div>
      </header>
    </>
  );
};
export default Navbarmenu;

