import React from "react";
import aboutImage from "../images/aboutus_image.png";
import company1 from "../images/company_cover_1stpage.png";
import weBelieves from "../images/business-meeting-1company.png";
import whyChooseUs from "../images/business-meeting2-company.png";
import atulyaItPark from "../images/gallery/atulya-it-park-1.jpg";
import robo10 from "../images/gallery/robotronix-india10.jpg";
import robo11 from "../images/gallery/robotronix-india11.jpg";
import robo17 from "../images/gallery/robotronix-india17.jpg";

const Company = () => {
  return (
    <>
      <div className="company">
        <div className="aboutBg vision">
          <div className="sec1 common_space diagonal-box bg-one">
            {/* <div className="container">
            <h2 className="text-center pt-5">About Us</h2>
          </div> */}
            <div className="container ">
              <div className="row align-items-center">
                <div className="col-md-6 content">
                  <p>
                    Our vision is to help our customers and eliminate the
                    barriers between ideas and realities. To help them harness
                    the power of technology to deliver new outcomes for their
                    business.
                  </p>
                  <p>
                    The objective of our organization is to explore indefinitely
                    in the innovative world of technology service provider.
                  </p>
                </div>
                <div className="col-md-6 img">
                  <img src={company1} alt="" className="img-fluid"></img>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="aboutBg">
          <div className="about_us common_space">
            <div className="container">
              <h2 className="text-center">About Us</h2>
            </div>
            <div className="container sec2 ">
              <div className="row align-items-center">
                <div className="col-md-6 content">
                  <h3>Who We Are</h3>
                  <p>
                    BitByte Software Technologies located in Indore, with
                    capabilities to service Clients across M.P., is a rapidly
                    growing Website Design, Development and custom software
                    development company.
                  </p>
                  <p>
                    We deliver Custom Application Development solutions within
                    the context of our client's business objectives and
                    requirement. BitByte has all the tools to help you conquer
                    your biggest dreams and complete your toughest objectives on
                    time. We specialize in everything 'digital' - from Website
                    designing, Logo designing to Content Management System, Copy
                    Writing, Custom Software Development, SEO, e-commerce sites
                    and many more.
                  </p>
                </div>
                <div className="col-md-6 img">
                  <img
                    src={aboutImage}
                    alt=""
                    className="abtusImg img-fluid"
                  ></img>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="gallery">
          <div className="container">
            <div className="content">
              <h3>BitByte Infrastructure</h3>
            </div>
            <main class="main">
              <div class="galleryContainer">
                <div class="card">
                  <div class="card-image">
                    <img src={atulyaItPark} alt="" />
                  </div>
                </div>
                <div class="card">
                  <div class="card-image">
                    <img src={robo10} alt="" />
                  </div>
                </div>
                <div class="card">
                  <div class="card-image">
                    <img src={robo11} alt="" />
                  </div>
                </div>
                <div class="card">
                  <div class="card-image">
                    <img src={robo17} alt="" />
                  </div>
                </div>
              </div>
            </main>
          </div>
        </div>

        <div className="sec2 common_space believes diagonal-box bg-one">
          <div className="container">
            <div className="row">
              <div className="col-md-6 img">
                <img src={weBelieves} alt="" className="img-fluid"></img>
              </div>
              <div className="col-md-6 content">
                <h2>We Believe</h2>

                <p>
                  At BitByte, we believe in delivering remarkable experiences to
                  both our customers as well as our team members. We make a
                  relationship with our customers and employees like friends to
                  create deeper connection. We take full responsibility for our
                  actions and deliver on our promise. We provide quality
                  services to customers and continuous improvement in all that
                  we do.
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="sec3 common_space chooseUs">
          <div className="container">
            <div className="row align-items-center">
              <div className="col-md-6 content">
                <h2>Why Choose Us?</h2>

                <p>
                  BitByte Software Technology interfaces with clients to
                  identify their requirement and accordingly, designs, develops
                  and implements an integrated and comprehensive IT or software
                  based solution. We put all of our energy into listening and
                  understanding your needs, developing your custom solution, and
                  striving to deliver above and beyond your requirements. We
                  take pride in our work and treat your business initiatives as
                  if they were ours, realizing that your success translates into
                  ours.
                </p>
                <p>
                  <ul className="fa-ul">
                    <li>
                      <span className="fa-li">
                        <i className="fa-solid fa-check-circle"></i>
                      </span>
                      High Tough Communication.
                    </li>
                    <li>
                      <span className="fa-li">
                        <i className="fa-solid fa-check-circle"></i>
                      </span>
                      Security Focused.
                    </li>
                    <li>
                      <span className="fa-li">
                        <i className="fa-solid fa-check-circle"></i>
                      </span>
                      Fast Response To Requests & Critical Response Time
                      Guaranteed.
                    </li>
                    <li>
                      <span className="fa-li">
                        <i className="fa-solid fa-check-circle"></i>
                      </span>
                      Committed to Quality.
                    </li>
                    <li>
                      <span className="fa-li">
                        <i className="fa-solid fa-check-circle"></i>
                      </span>
                      We want you to understand what we are doing and what the
                      problem is. No geek- speak here! High Tough Communication.
                    </li>
                    <li>
                      <span className="fa-li">
                        <i className="fa-solid fa-check-circle"></i>
                      </span>
                      We Help Solve Your Business Problems.
                    </li>
                    <li>
                      <span className="fa-li">
                        <i className="fa-solid fa-check-circle"></i>
                      </span>
                      A Proven Track Record.
                    </li>
                    <li>
                      <span className="fa-li">
                        <i className="fa-solid fa-check-circle"></i>
                      </span>
                      Satisfaction Guaranteed.
                    </li>
                    <li>
                      <span className="fa-li">
                        <i className="fa-solid fa-check-circle"></i>
                      </span>
                      We Are FUN To Work With.
                    </li>
                  </ul>
                </p>
              </div>
              <div className="col-md-6 img">
                <img src={whyChooseUs} alt="" className="img-fluid"></img>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Company;
