import React from "react";
import MobileApp from '../images/services/MobileApp.png';
import { Link } from "react-router-dom";
import Android from '../images/services/AndroidDevelopment.png';
const Mobile_App = () => {
    return (
        <div>
            <div className="mobile_app">
                <div className="container">
                    <i className="bubble"></i>
                    <i className="bubble"></i>
                    <i className="bubble"></i>
                    <div className="row align-items-center">
                        <div className="col-md-6">
                            <h3 className="mobile_app_title">Delivering Mobile App Innovation to Enterpreneurs, Startups & Business</h3>
                            {/* <Link to="#" className="btn btn-mobile">Get Started</Link> */}
                        </div>
                        <div className="col-md-6">
                            <img src={MobileApp} alt="" className="mobile_app_img"></img>
                        </div>
                    </div>
                </div>
            </div>
            <div className="android_app_development">
                <div className="container">
                    <h2 className="android_app_title text-center">Mobile App Development</h2>
                    <div className="row align-items-center mt-5">
                        <div className="col-md-6">
                            <h3 className="android_subtitle">Android App Development</h3>
                            <p className="android_para pt-3">In today's world Android phone is became boom in the market, which made a basic need to create your
                                own mobile application. Android - The Google's best invention, which made most of the task just on
                                finger tips. Thousands of ideas are there in the human mind and thousands of new android application
                                is being driven daily.
                            </p>
                            <p className="android_para">
                                Your dream of success! At Bitbyte Software Technology we are ready to make your unique idea into the
                                real world. Android team at Bitbyte Software Technology is keen to make revolutionist way to change
                                your business trend and to boost up towards the new heights of your goals.
                            </p>
                            <div className="mt-4">
                                <h6 className="android_app_line">Accuracy of our Business Analysts will give you the fruitful results for android apps likewise:</h6>
                            </div>
                            <p className="android_app_point">
                                <i className="fa fa-check-circle"></i>&nbsp;&nbsp;<span>Awesome UI Design</span>
                            </p>
                            <p className="android_app_point">
                                <i className="fa fa-check-circle"></i>&nbsp;&nbsp;<span>Time saving</span>
                            </p>
                            <p className="android_app_point">
                                <i className="fa fa-check-circle"></i>&nbsp;&nbsp;<span>Enhancing Productivity</span>
                            </p>
                            <p className="android_app_point">
                                <i className="fa fa-check-circle"></i>&nbsp;&nbsp;<span>Cost Reducing</span>
                            </p>
                            <p className="android_app_point">
                                <i className="fa fa-check-circle"></i>&nbsp;&nbsp;<span>Transparency of your business</span>
                            </p>
                            <p className="android_app_point">
                                <i className="fa fa-check-circle"></i>&nbsp;&nbsp;<span>Smooth operations</span>
                            </p>
                            <p className="android_app_point">
                                <i className="fa fa-check-circle"></i>&nbsp;&nbsp;<span>Security which no one can breach</span>
                            </p>


                        </div>
                        <div className="col-md-6 text-center">
                            <img src={Android} alt="" className="img-fluid"></img>
                            <div>
                                <Link to="#" className="btn btn-knowmore">Know More</Link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="android_services_offering android_diagonal_box">
                <div className="container android_content">
                    <h2 className="android_services_title text-center">Services Offering</h2>
                    <p className="android_services_para text-center">Developers of Bitbyte Software Technology are always ready to provide you work on time, with their best
                        deliverable's. We also provide Android App customization, upgrading to the latest versions, all API integrations,
                        reliable Google map navigation, Bluetooth, WiFi, GPS support, and all other whichever is applicable.
                    </p>
                    <p className="android_services_point">
                        <i className="fa fa-check-circle"></i>&nbsp;&nbsp;<span>Apps for on-demand services.</span>
                    </p>
                    <p className="android_services_point">
                        <i className="fa fa-check-circle"></i>&nbsp;&nbsp;<span>Custom app development.</span>
                    </p>
                    <p className="android_services_point">
                        <i className="fa fa-check-circle"></i>&nbsp;&nbsp;<span>IoT based apps.</span>
                    </p>
                    <p className="android_services_point">
                        <i className="fa fa-check-circle"></i>&nbsp;&nbsp;<span>Enterprise mobility apps.</span>
                    </p>
                </div>
            </div>
        </div>
    );
};
export default Mobile_App;