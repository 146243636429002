import React from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Company from "../AboutUs/Company";
import Home from "../Home/Home";
import Careers from "../AboutUs/Careers";
import JobDescrption from "../AboutUs/JobDescrption";
import Footer from "../Common/Footer";
import Contact from "../ContactPage/Contact";
import Portfolio from "../PortfolioPage/Portfolio";
import Header from "../Common/Header";
import CMSDevelopment from "../Services/CMSDevelopment";
import ContactHome from "../Home/ContactHome";
import WebDevelopment from "../Services/WebDevelopment";
import InternetOfThings from "../Services/InternetOfThings";
import MobileAppDevelopment from "../Services/MobileAppDevelopment";
import Digital_Marketing from "../Services/DigitalMarketing";

const Routing = () => {
  return (
    <>
      <BrowserRouter>
        <Routes>
          <Route
            exact
            path="/"
            element={
              <div>
                <Header />
                <Home />
                <ContactHome />
                <Footer />
              </div>
            }
          />
          <Route
            exact
            path="/company"
            element={
              <div>
                <Header />
                <Company />
                <Footer />
              </div>
            }
          />
          <Route
            exact
            path="/careers"
            element={
              <div>
                <Header />
                <Careers />
                <Footer />
              </div>
            }
          />

          <Route
            exact
            path='/jobdescription/:jid'
            element={
              <div>
                <Header />
                <JobDescrption />
                <Footer />
              </div>
            }
          />

          <Route
            exact
            path="/portfolio"
            element={
              <div>
                <Header />
                <Portfolio />
                <Footer />
              </div>
            }
          />
          <Route
            exact
            path="/contact"
            element={
              <div>
                <Header />
                <Contact />
                <Footer />
              </div>
            }
          />
          <Route
            exact
            path="/web-development"
            element={
              <div>
                <Header />
                <WebDevelopment />
                <ContactHome />
                <Footer />
              </div>
            }
          />
          <Route
            exact
            path="/internet-of-things"
            element={
              <div>
                <Header />
                <InternetOfThings />
                <ContactHome />
                <Footer />
              </div>
            }
          />
          <Route
            exact
            path="/mobile-app-development"
            element={
              <div>
                <Header />
                <MobileAppDevelopment />
                <ContactHome />
                <Footer />
              </div>
            }
          />
          <Route
            exact
            path="/cms-development"
            element={
              <div>
                <Header />
                <CMSDevelopment />
                <ContactHome />
                <Footer />
              </div>
            }
          />
          <Route
            exact
            path="/digital-marketing"
            element={
              <div>
                <Header />
                <Digital_Marketing />
                <ContactHome />
                <Footer />
              </div>
            }
          />
        </Routes>
      </BrowserRouter>
    </>
  );
};

export default Routing;
