import React from "react";
import { Slide } from 'react-slideshow-image';
import 'react-slideshow-image/dist/styles.css';
import Carousel from 'react-bootstrap/Carousel';
import banner_image from "../images/banner-image.png";
import success_image from "../images/success-image.png";
import web_development from "../images/software-development.png";
import cms_solutions from "../images/developer.png";
import mobile_app from "../images/photo-sharing.jpg";
import iot from "../images/iotttsssaaa.png";
import marketing from "../images/social-media-marketing.png";
import block_chain from "../images/bitcoin.png";
import project_image from "../images/project.png";
import { useEffect } from "react";
import best_price from "../images/best-price-tag.png";
import hourly_basis from "../images/hourly-basis.png";
import dedicate_staff from "../images/dedicate-staff-hire3.png";
import testimonial1 from "../images/Pritthish.jpg";
import testimonial2 from "../images/Akhil.jpg";
import testimonial3 from "../images/Arvind.jpg";
import strategic_marketing from "../images/portfolio/xpressiongraphics-large.png";
import product from "../images/portfolio/electronicsproduct.png";
import kissanlive from "../images/portfolio/kissanlive.png";
import fortitude from "../images/portfolio/fortitudegroup-large.png";
import manviya from "../images/portfolio/manviya.png";
import robotronix from "../images/portfolio/robotronix.png";
import { Link } from "react-router-dom";

const Home = () => {
	useEffect(() => {
		counter();
	}, []);

	function counter() {
		const counters = document.querySelectorAll(".counter");
		counters.forEach((counter) => {
			counter.innerText = "0";
			const updateCounter = () => {
				const target = +counter.getAttribute("data-target");
				const count = +counter.innerText;
				const increment = target / 200;
				if (count < target) {
					counter.innerText = `${Math.ceil(count + increment)}`;
					setTimeout(updateCounter, 1);
				} else counter.innerText = target + "+";
			};
			updateCounter();
		});
	}

	// function reveal() {
	// 	var reveals = document.querySelectorAll(".reveal");

	// 	for (var i = 0; i < reveals.length; i++) {
	// 		var windowHeight = window.innerHeight;
	// 		var elementTop = reveals[i].getBoundingClientRect().top;
	// 		var elementVisible = 30;

	// 		if (elementTop < windowHeight - elementVisible) {
	// 			reveals[i].classList.add("active");
	// 			console.log("working");
	// 		} else {
	// 			reveals[i].classList.remove("active");
	// 			console.log("working");
	// 		}
	// 	}
	// }
	// window.addEventListener("scroll", reveal);

	return (
		<div>
			<div className="banner_section">
				<div className="container-fluid">
					<div className="row align-items-center banner_row">
						<div className="col-md-6 banner_col pt-5">
							<h2 className="banner_title">Business Planning</h2>
							<h2 className="banner_title">Digital Technology</h2>
							<h2 className="banner_title">Modern Solution !</h2>
							<p className="banner_para">We deliver Custom Application Development solutions within the context of
								our client’s business objectives and requirement.</p>
							<div className="text-center pt-3">
								<Link to="#" className="btn text-white btn_explore mb-3"><b>Explore</b></Link>{" "}
								<Link to="/contact" className="btn text-white btn_contact mb-3"><b>Contact</b></Link>
							</div>
						</div>
						<div className="col-md-6 pt-3">
							<img src={banner_image} alt="img" className="banner_img"></img>
						</div>
					</div>
				</div>
			</div>
			<div className="success_section mt-5">
				<div className="container success_subsection">
					<div className="row align-items-center">
						<div className="col-md-6 reveal fade-left">
							<p className="success_title">Welcome to BitByte</p>
							<h2 className="success_subtitle">Your success is our success</h2>
							<img src={success_image} alt="img" height="auto"></img>
						</div>
						<div className="col-md-6 reveal fade-right">
							<p className="success_para">BitByte Software Technology interfaces with clients to identify their 
							requirement and accordingly, designs, develops and implements an integrated and comprehensive IT 
							or software based solution.</p>
							<p className="success_quote pt-3"><i>“At BitByte, We take full responsibility for our actions and 
							deliver on our promise. We provide quality services to customers and continuous improvement in all 
							we do.”</i></p>
						</div>
					</div>
				</div>
			</div>
			<div className="service_section service_diagonal_box">
				<div className="container-fluid ">
					<div className="row align-items-center">
						<div className="col-md-4 service_content reveal fade-right">
							<h2 className="service_heading">Services</h2>
							<p className="pt-5 service_para">Everything for entire digital transformation strategy. Right here!
								From Strategy, Design, User Experience to Custom Web, Mobile, IoT application development and deployment,
								BitByte India gives you all the solutions you need.</p>
						</div>
						<div className="col-md-8 service_content reveal fade-left">
							<div className="row">
								<div className="col-md-4">
									<div className="card text-center align-items-center mb-4">
										<div className="card-body">
											<Link to="/web-development"><img src={web_development} alt="img" className="img_service"></img></Link>
											<p className="pt-3"><Link to="/web-development">Web Development</Link></p>
										</div>
									</div>
								</div>
								<div className="col-md-4">
									<div className="card card-top text-center align-items-center mb-4">
										<div className="card-body">
											<Link to="/cms-development"><img src={cms_solutions} alt="img" className="img_service"></img></Link>
											<p className="pt-3"><Link to="/cms-development">CMS Solutions</Link></p>
										</div>
									</div>
								</div>
								<div className="col-md-4">
									<div className="card text-center align-items-center mb-4">
										<div className="card-body">
											<Link to="/mobile-app-development"><img src={mobile_app} alt="img" className="img_service"></img></Link>
											<p className="pt-3"><Link to="/mobile-app-development">Mobile App Development</Link></p>
										</div>
									</div>
								</div>
							</div>
							<div className="row pt-3">
								<div className="col-md-4">
									<div className="card text-center align-items-center mb-4">
										<div className="card-body">
											<Link to="/internet-of-things"><img src={iot} alt="img" className="img_service"></img></Link>
											<p className="pt-3"><Link to="/internet-of-things">Internet of Things</Link></p>
										</div>
									</div>
								</div>
								<div className="col-md-4">
									<div className="card card-up text-center align-items-center mb-4">
										<div className="card-body">
											<Link to="/digital-marketing"><img src={marketing} alt="img" className="img_service"></img></Link>
											<p className="pt-3"><Link to="/digital-marketing">Digital Marketing</Link></p>
										</div>
									</div>
								</div>
								<div className="col-md-4">
									<div className="card text-center align-items-center mb-4">
										<div className="card-body">
											<Link to="#"><img src={block_chain} alt="img" className="img_service"></img></Link>
											<p className="pt-3"><Link to="#">Cyber Security/Block Chain</Link></p>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div className="project_section">
				<div className="container-fluid">
					<div className="row align-items-center">
						<div className="col-md-6 reveal fade-right">
							<img src={project_image} alt="img" height="auto" width="100%"></img>
						</div>
						<div className="col-md-6 reveal fade-left">
							<p className="project_para">Start Your Project</p>
							<h2 className="project_heading">Grow Your Business With Our Strategy</h2>
							<p className="project_para2 pt-2">A business growth strategy is not a simple, or unified thing that 
							your business can do to expand. Simply put, a business growth strategy is a combination of several 
							strategies that you combine together to reach maximum results.</p>
							<div className="text-center pt-3">
								<Link to="#" className="btn text-white btn_explore mb-3"><b>Explore</b></Link>{" "}
								<Link to="/contact" className="btn text-white btn_contact mb-3"><b>Contact</b></Link>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div className="portfolio_section">
				<p className="portfolio_para text-center">Portfolio</p>
				<h2 className="portfolio_heading text-center">Our Work</h2>
				<Slide slidesToScroll={1} slidesToShow={1} indicators={true} autoplay={true} responsive={[{
					breakpoint: 800,
					settings: {
						slidesToShow: 3,
						slidesToScroll: 3
					}
				}, {
					breakpoint: 500,
					settings: {
						slidesToShow: 2,
						slidesToScroll: 2
					}
				}]}>
					<div className="card">
						<div className="card-header p-0">
							<Link to ="http://xpressiongraphics.com/" >
								<img src={strategic_marketing} alt="" className="portfolio_img"></img>
							</Link>
							
						</div>
						<div className="card-body p-4">
							<div className="d-flex justify-content-between">
								<p className="company_name">Xpression Graphics</p>
								<p className="company_category">Graphics Design</p>
							</div>
						</div>
					</div>
					<div className="card">
						<div className="card-header p-0">
							<Link to ="http://dangieshop.com/">
								<img src={product} alt="" className="portfolio_img"></img>
							</Link>
						</div>
						<div className="card-body p-4">
							<div className="d-flex justify-content-between">
								<p className="company_name">Dangie E-Shop</p>
								<p className="company_category">Electronics E-Commerce</p>
							</div>
						</div>
					</div>
					<div className="card">
						<div className="card-header p-0">
							<Link to="/portfolio">
								<img src={kissanlive} alt="" className="portfolio_img"></img>
							</Link>
						</div>
						<div className="card-body p-4">
							<div className="d-flex justify-content-between">
								<p className="company_name">Kissan Live</p>
								<p className="company_category">Farming Seeds</p>
							</div>
						</div>
					</div>
					<div className="card">
						<div className="card-header p-0">
							<Link to="http://fortitudegroup.in/">
								<img src={fortitude} alt="" className="portfolio_img"></img>
							</Link>
						</div>
						<div className="card-body p-4">
							<div className="d-flex justify-content-between">
								<p className="company_name">Fortitude Group</p>
								<p className="company_category">Advisory & Consulting</p>
							</div>
						</div>
					</div>
					<div className="card">
						<div className="card-header p-0">
							<Link to="/portfolio">
								<img src={manviya} alt="" className="portfolio_img"></img>
							</Link>
						</div>
						<div className="card-body p-4">
							<div className="d-flex justify-content-between">
								<p className="company_name">Manviya</p>
								<p className="company_category">Charity Firm</p>
							</div>
						</div>
					</div>
					<div className="card">
						<div className="card-header p-0">
							<Link to="http://robotronix.co.in/">
								<img src={robotronix} alt="" className="portfolio_img"></img>
							</Link>
						</div>
						<div className="card-body p-4">
							<div className="d-flex justify-content-between">
								<p className="company_name">Robotronix India</p>
								<p className="company_category">Electronics Company</p>
							</div>
						</div>
					</div>
				</Slide>
			</div>


			<div class="digital_section">
				<div class="container-fluid">
					<h2 class="digital_title text-center">Get ready to own digital era</h2>
					<p class="digital_para text-center">We help enterprises to create value for its customers, partners and employees 
					and grow their business through digital strategy consulting and technology services</p>
					<div class="row pt-3 reveal fade-left">
						<div class="col-md-10 offset-md-1">
							<div class="row">
								<div class="col-md-3 col-sm-6">
									<div class="card text-center align-items-center card_bgdark mb-3">
										<div class="card-body">
											<div class="icon_dark"><i class="fa fa-briefcase"></i></div>
											<div class="counter" data-target="250" ></div>
											<p class="counter_title_dark">Completed Projects</p>
										</div>
									</div>
								</div>
								<div class="col-md-3 col-sm-6">
									<div class="card text-center align-items-center card_bglight mb-3">
										<div class="card-body">
											<div class="counter_icon"><i class="fa-solid fa-thumbs-up"></i></div>
											<div class="counter counting" data-target="190"></div>
											<p class="counter_title">Happy Clients</p>
										</div>
									</div>
								</div>
								<div class="col-md-3 col-sm-6">
									<div class="card text-center align-items-center card_bgdark mb-3">
										<div class="card-body">
											<div class="icon_dark"><i class="fa fa-users"></i></div>
											<div class="counter" data-target="20" ></div>
											<p class="counter_title_dark">Team Members</p>
										</div>
									</div>
								</div>
								<div class="col-md-3 col-sm-6">
									<div class="card text-center align-items-center card_bglight mb-3">
										<div class="card-body">
											<div class="counter_icon"><i class="fa fa-briefcase"></i></div>
											<div class="counter counting" data-target="7" ></div>
											<p class="counter_title">Years Experience</p>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div className="pricelist_section">
				<div className="container text-center">
					<p className="main_title pt-5">Price List</p>
					<h2 className="subtitle">Choose the plan according to your needs</h2>
					<div className="row pt-5 reveal fade-right">
						<div className="col-md-4">
							<div className="card rounded-0 mb-3">
								<div className="card-body">
									<img src={best_price} alt="img" height="98px" width="98px"></img>
									<h4 className="price_title pt-4">Fixed Price</h4>
									<p className="pt-3 price_para">Fixed Price model helps organizations to define and control project cost.
										Fixed price projects are gaining popularity since they are cost effective. Bitbyte is providing fixed
										price services with our dedicated internal resource.</p>

								</div>
							</div>


						</div>
						<div className="col-md-4">
							<div className="card rounded-0 mb-3">
								<div className="card-body">
									<img src={hourly_basis} alt="img" height="98px" width="98px"></img>
									<h4 className="price_title pt-4">Hourly Basis</h4>
									<p className="pt-3 price_para">This model is ideal for Projects where the scope cannot be defined in
										advance. Pricing of Project varies due to Project Life cycle. We assure you to give quality time to your
										project to minimize cost.</p>

								</div>
							</div>


						</div>
						<div className="col-md-4">
							<div className="card rounded-0 mb-3 price_card3">
								<div className="card-body">
									<img src={dedicate_staff} alt="img" height="98px" width="98px"></img>
									<h4 className="price_title pt-4">Dedicated Resource</h4>
									<p className="pt-3 price_para">This model is suitable for the organization that needs a resource for a
										longer period of time. This saves organizations from training resource again and again on their project.
										Bitbyte is providing this service to its clients globally.</p>

								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div className="testimonial_section">
				<div className="container-fluid">
					<p className="pt-5 testimonial_title">Testimonial</p>
					<h2 className="testimonial_heading">What People Say</h2>
					<p className="testimonial_para pt-2">This is what our clients have to say about us</p>
					<div className="row">
						<div className="col-md-10 offset-md-1">
							<div className="card testimonial_card">
								<div className="card-body">
									<Carousel>
										<Carousel.Item>
											<img src={testimonial1} alt="" className="testimonial_img" />
											<h4 className="name">Pritthish Gupta</h4>
											<p className="designation">Co-Founder (Fortitude Group)</p>
											<i className="fa fa-star"></i>
											<i className="fa fa-star"></i>
											<i className="fa fa-star"></i>
											<i className="fa fa-star"></i>
											<i className="fa fa-star"></i>
											<div className="description"><sup><i className="fa fa-quote-left"></i></sup>&nbsp;&nbsp;
												BitByte delivered an elegant website as per our requirements. The design has drawn
												applause. I am happy with the change we desired to bring and have no hesitation in
												recommending BitByte to anyone who needs a website or needs to redesign their existing
												website.</div>
										</Carousel.Item>
										<Carousel.Item>
											<img src={testimonial2} alt="" className="testimonial_img" />
											<h4 className="name">Akhil Bhatia</h4>
											<p className="designation">Director (Xpression Graphics)</p>
											<i className="fa fa-star"></i>
											<i className="fa fa-star"></i>
											<i className="fa fa-star"></i>
											<i className="fa fa-star"></i>
											<i className="fa fa-star"></i>
											<div className="description"><sup><i className="fa fa-quote-left"></i></sup>&nbsp;&nbsp;
												We would highly recommend BitByte. We have been using their services for almost one
												year and have nothing but good things to say about them. They are professional and
												their communication on projects is outstanding.</div>
										</Carousel.Item>
										<Carousel.Item>
											<img src={testimonial3} alt="" className="testimonial_img" />
											<h4 className="name">Arvind Dangi</h4>
											<p className="designation">Distributor (Dangi E-Shop)</p>
											<i className="fa fa-star"></i>
											<i className="fa fa-star"></i>
											<i className="fa fa-star"></i>
											<i className="fa fa-star"></i>
											<i className="fa fa-star"></i>
											<div className="description"><sup><i className="fa fa-quote-left"></i></sup>
												&nbsp;&nbsp;I wanted to thank you for the hard work you put into our Dangi
												E-Shop website. It’s the best it has ever looked, and we are really happy
												with it!</div>
										</Carousel.Item>
									</Carousel>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};
export default Home;
